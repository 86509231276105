import { required, maxLength } from "vuelidate/lib/validators";
import { onlyAlphanumeric2, onlyAlphanumericGuion } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        sender: { required, onlyAlphanumeric2, maxLength: maxLength(20) },
        recipient: { required, onlyAlphanumeric2, maxLength: maxLength(20) },
        comunicationAgreement: { required, onlyAlphanumeric2, maxLength: maxLength(20) },
        messageReference: { required, onlyAlphanumeric2, maxLength: maxLength(20) },
        messageTypeIdentifier: { required },
        messageTypeVersion: { required },
        documentNumber: { required, onlyAlphanumeric2, maxLength: maxLength(20) },
        messageType: { required },
        dischargeVoyage: { required, onlyAlphanumericGuion, maxLength: maxLength(20) },
        placeDeparture: { required },
        actualTimeDeparture: { required },
        estimateTimeDeparture: { required },
        actualTime: { required },
        estimateTime: { required },
        carrierCode: { required },
        nextPort: { required },
        MTIdentId: { required },
        StandardDocumentId: { required },
        idMeans: { onlyAlphanumericGuion, maxLength: maxLength(20) },
        loadingVoyage: { required, onlyAlphanumericGuion, maxLength: maxLength(20) },
    }
}

